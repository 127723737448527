import { Bounce, toast } from "react-toastify";
import { getIsUserLoggedIn } from "../Api/api";
import { DEVICE_ACCESS_CONTROL, LOCAL_STORAGE_KEY, OTT_POSTER_TYPE, OTTROUTETYPE, RAILTYPE, VODTYPE } from "../constant";

export const utility = {};

utility.getValue = function (key) {
    return JSON.parse(localStorage.getItem(key));
};


utility.setValue = function (key, value) {

    if (value == undefined) {
        value = null;
    }

    localStorage.setItem(key, JSON.stringify(value));
};

utility.deleteValue = function (key) {
    localStorage.removeItem(key);
};

utility.getPosterUrl = (posters, posterType) => {

    if (!posters || typeof posters == 'string' || posters.length == 0) {
        return ""
    }

    var poster = posters.find((x) => x.OttPosterType.SID == posterType);

    return poster ? poster.Url : posters[0].Url

}

utility.getValidPosterFromRail = (railType) => {
    switch (railType) {
        case RAILTYPE.Rail:
            return OTT_POSTER_TYPE.Portrait;
        case RAILTYPE.CircleRail:
            return OTT_POSTER_TYPE.Circle;
        case RAILTYPE.PotraitRail:
            return OTT_POSTER_TYPE.Portrait;
        case RAILTYPE.LandscapeRail:
            return OTT_POSTER_TYPE.Landscape;
        case RAILTYPE.SquareRail:
            return OTT_POSTER_TYPE.Square;
        default:
            return OTT_POSTER_TYPE.Free;
    }
}

utility.getMediaCategoryType = (value) => {
    switch (value) {
        case 0:
            return "";
        case 1:
            return "Program";
        case 2:
            return "Episode";
        case 3:
            return "Movie";
        case 4:
            return "Interstitial";
        case 5:
            return "Commercial";
        case 6:
            return "Filler";
        case 7:
            return "Music";
        case 8:
            return "Series";
        case 9:
            return "Season";
        case 10:
            return "Trailer";
        default:
            return "";
    }
}

utility.formatMilliseconds = (milliseconds) => {
    var hours = Math.floor(milliseconds / 1000 / 60 / 60);
    var minutes = Math.floor((milliseconds / 1000 / 60 / 60 - hours) * 60);
    return hours + "h " + minutes + "m ";
}

utility.onAssetClick = async (asset, ottSetting, navigate, isMobile = false) => {
    let user = utility.getValue(LOCAL_STORAGE_KEY.user);
    if (ottSetting?.deviceAccessControl == DEVICE_ACCESS_CONTROL.Login) {
        let isUserLoggedIn = await getIsUserLoggedIn(user?._id);
        if (user && !isUserLoggedIn.success) {
            toast('Device removed. Please login again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            utility.setValue(LOCAL_STORAGE_KEY.user, null);
            navigate('/login');
            return;
        }
    }

    const isFree = asset.VodTypes?.SID ? asset.VodTypes?.SID == VODTYPE.FREE : false;
    const canPlay = isFree || utility.getValue(LOCAL_STORAGE_KEY.user)?.PaymentPlan?.length > 0 ? true : false ?? false;
    console.log(canPlay);
    if (!user) {
        navigate('/login');
        return
    }
    console.log(asset.routeType);
    switch (asset.routeType) {
        case OTTROUTETYPE.Page:
            var pages = utility.getValue('pages');
            var page_id = asset.page_id;
            let page = pages.find(p => p._id === page_id);
            if (page) {
                navigate(isMobile ? '/mobile' : '/', { state: { page: page } })
            }
            break;
        case OTTROUTETYPE.Player:
            if (canPlay) {
                navigate('/liveTv', { state: { asset: asset } })
            } else {
                navigate('/plans')
            }
            break;
        case OTTROUTETYPE.PI_Page:
            navigate('/' + asset.Title.toString().replace(' ', '_'), { state: { asset: asset, ottSetting: ottSetting, isMobile: isMobile } })
            break;
        default:
            if (canPlay) {
                navigate('/liveTv', { state: { asset: asset } })
            } else {
                navigate('/plans')
            }
            break;
    }
}