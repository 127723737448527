import React, { useEffect, useState } from 'react';
import Keypad from './keypad';
import './login.css';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import LoginRightSide from './loginRightSide';
import { getOttSettings } from '../../Api/api';
import FullKeypad from '../homePage/search/fullkeypad';

const Login = () => {
    const { ref, focusKey } = useFocusable();

    const [mobileNumber, setMobileNumber] = useState('');
    const [showVerifyOTP, setShowVerifyOTP] = useState(false);

    const [ottSetting, setOttSetting] = useState({});
    const [showNumPad, setShowNumPad] = useState(true);

    useEffect(() => {
        loadOttSettings();
        document.addEventListener('keyup', onKeyUp);
        return () => {
            document.removeEventListener('keyup', onKeyUp);
        }
    }, []);

    const loadOttSettings = async () => {
        const ottSettingRes = await getOttSettings();
        if (ottSettingRes.success) {
            console.log(ottSettingRes);
            setOttSetting({ ...ottSettingRes.data, piPageSetting: ottSettingRes?.piPageSetting });
        }
    }
    const keyboard = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '@', '.', '_', '-'];
    const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

    const onKeyUp = (e) => {
        if (e.key.length == 1 && keyboard.includes(e.key)) {
            if (showVerifyOTP && mobileNumber.length == 4) {
                return
            }
            if (showVerifyOTP) {
                if (numbers.includes(e.key)) {
                    setMobileNumber((old) => (old + e.key));
                }
            } else {
                setMobileNumber((old) => (old + e.key));
            }
        }
    }

    return (
        <FocusContext.Provider value={focusKey}>
            <div id="login" ref={ref} >
                <div className='loginLogo'>
                    <img src={ottSetting?.logo ?? ""} alt="logo" />
                </div>
                <div id='loginContent'>
                    {/* Pass the focusKeyParam prop to Keypad */}
                    {!showVerifyOTP && !showNumPad && <><FullKeypad style={{
                        width: "50vw",
                        height: "90%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: " center",
                        justifyContent: " center",
                    }} fromLogin={true} setSearchKey={setMobileNumber} setShowNumPad={setShowNumPad} />
                        <div style={{
                            content: "",
                            backgroundColor: "white",
                            position: "absolute",
                            width: "2px",
                            height: "40%",
                            top: "60%",
                            left: "50%",
                            transform: " translate(-50%, -50%)",
                            display: "block",
                        }}>
                        </div></>}
                    {(showVerifyOTP || showNumPad) && <Keypad setMobileNumber={setMobileNumber} showVerifyOTP={showVerifyOTP} setShowNumPad={setShowNumPad} />}
                    {/* Enter mobile number */}
                    <LoginRightSide mobileNumber={mobileNumber} showVerifyOTP={showVerifyOTP} setShowVerifyOTP={setShowVerifyOTP} setMobileNumber={setMobileNumber} ottSetting={ottSetting} />
                </div>
            </div>
        </FocusContext.Provider>
    );
}

export default Login;
