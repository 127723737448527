import React, { useState, useEffect } from 'react';
import {
    Button, Card, CardContent, List, ListItem, ListItemIcon,
    ListItemText, IconButton, Dialog, DialogTitle, DialogContent,
    DialogActions, CircularProgress, Box, Typography, ThemeProvider, createTheme
} from '@mui/material';
import { FocusContext, useFocusable, init } from '@noriginmedia/norigin-spatial-navigation';
import { DialogButton } from './dialogButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { addDevice, getLoggedDevices, removeDevice } from '../../../Api/api';
import { LOCAL_STORAGE_KEY, PLAYER } from '../../../constant';
import { utility } from '../../../utility/utility';

// Initialize spatial navigation
init({
    debug: false,
    visualDebug: false,
});

const RemoveDevicePreview = () => {

    // get data from state
    const location = useLocation();
    const { user, fromLogin } = location.state;

    const [devices, setDevices] = useState([]);
    const [serialNumber, setSerialNumber] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [deviceToDelete, setDeviceToDelete] = useState(null);

    const { ref: mainRef, focusKey: mainFocusKey, focusSelf: focusMain } = useFocusable({
        focusable: true,
        saveLastFocusedChild: true,
        trackChildren: true,
        autoRestoreFocus: true,
        isFocusBoundary: true,
        focusKey: "devices-list",
    });

    const { ref: dialogRef, focusKey: dialogFocusKey, focusSelf: focusDialog } = useFocusable({
        focusable: true,
        saveLastFocusedChild: true,
        trackChildren: true,
        autoRestoreFocus: true,
        isFocusBoundary: true,
        focusKey: "dialog-container",
        preferredChildFocusKey: "dialog-cancel-button",
    });

    const navigate = useNavigate();

    useEffect(() => {
        loadDevcices();
    }, []);

    useEffect(() => {
        if (!openDialog) {
            focusMain();
        } else {
            focusDialog();
        }
    }, [openDialog, devices]);

    useEffect(() => {
        document.addEventListener('keydown', onBack);
        return () => {
            document.removeEventListener('keydown', onBack);
        }
    }, []);

    const loadDevcices = async () => {
        setIsLoading(true);
        try {
            let ipaddressRes = await fetch('https://api.ipify.org?format=json');
            let ipaddress = await ipaddressRes.json().then((data) => data['ip']);
            setSerialNumber(ipaddress);
        } catch (error) {
            console.log("error fetching ip", error.message);
        }
        let res = await getLoggedDevices(user._id);
        console.log(res);
        if (res.success) {
            setDevices(res.data.map((d, i) => {
                return {
                    id: i,
                    ...d
                }
            }));
        } else {
            setDevices([]);
            console.log(res);
        }
        setIsLoading(false);
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        let res = await removeDevice(user._id, deviceToDelete.serialNumber);
        if (res.success) {
            setDevices(devices.filter(device => device.id !== id));
            if (fromLogin) {
                let addDeviceRes = await addDevice(user._id, (user.Name + '`s Device'));
                if (!addDeviceRes.success) {
                    utility.setValue(LOCAL_STORAGE_KEY.user, null);
                    navigate('/login');
                } else {
                    utility.setValue(LOCAL_STORAGE_KEY.user, user);
                    navigate('/');
                }
            } else {
                if (serialNumber == deviceToDelete.serialNumber) {
                    utility.setValue(LOCAL_STORAGE_KEY.user, null);
                    navigate('/login');
                }
            }
        }
        setIsLoading(false);
        setOpenDialog(false);
    };

    const onBack = (e) => {
        const { keyCode } = e;

        if (keyCode === PLAYER.VK_BACK_SPACE || keyCode === PLAYER.VK_BACK || keyCode === PLAYER.BACK_LG || keyCode === PLAYER.RETURN) {
            e.preventDefault();
            navigate(-1);
        }
    }

    const confirmDelete = (device) => {
        setDeviceToDelete(device);
        setOpenDialog(true);
        focusDialog();
    };

    const closeDialog = () => {
        setOpenDialog(false);
        focusMain();
    };

    const DeviceItem = ({ device }) => {
        const [onMouseFocus, setOnMouseFocus] = useState(false);
        const { ref, focused } = useFocusable({
            focusKey: `device-${device.id}`,
            onEnterPress: () => confirmDelete(device),
            onFocus: () => {
                // scroll to center
                const element = document.getElementById(`device-${device.id}`);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            },
        });

        return (
            <Card id={`device-${device.id}`} ref={ref} sx={{ mb: 2, backgroundColor: (focused || onMouseFocus) ? 'primary.main' : 'background.paper', transition: 'background-color 0.3s' }} onMouseEnter={() => setOnMouseFocus(true)}
                onMouseLeave={() => setOnMouseFocus(false)}>
                <CardContent>
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <div style={{ width: 50, height: 50, backgroundColor: 'black', color: "white", borderRadius: '50%', marginRight: "10px", display: "flex", justifyContent: "center", alignItems: "center" }} className='fa fa-mobile fa-2x' />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography variant="subtitle1">{device.deviceName}</Typography>}
                            secondary={new Date(device.lastLoginTime).toLocaleString()}
                        />
                        <Box display="flex" alignItems="center">
                            {serialNumber === device.serialNumber && (
                                <div style={{ width: 10, height: 10, backgroundColor: 'green', borderRadius: '50%', animation: 'pulse 2s infinite' }} />
                            )}
                            <IconButton color="error" onClick={() => confirmDelete(device)}>
                                <div className="fa fa-trash" />
                            </IconButton>
                        </Box>
                    </ListItem>
                </CardContent>
            </Card>
        );
    };

    return (
        <div style={{ padding: "20px", backgroundColor: "black", color: "white" }} className="ContentWrapper">
            <FocusContext.Provider value={mainFocusKey}>
                <Box ref={mainRef}>
                    <Typography variant="h4" component="h1" gutterBottom style={{ display: "flex" }}>
                        My Devices
                    </Typography>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                            <CircularProgress />
                        </Box>
                    ) : devices.length === 0 ? (
                        <Box textAlign="center" mt={30}>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "10px" }}>
                                <div style={{ width: 80, height: 80, backgroundColor: 'white', color: "black", borderRadius: '50%', display: "flex", justifyContent: "center", alignItems: "center" }} className='fa fa-mobile fa-3x' />
                            </div>
                            <Typography variant="h6" color="text.light">No devices found</Typography>
                        </Box>
                    ) : (
                        <List>
                            {devices.map((device) => (
                                <DeviceItem key={device.id} device={device} />
                            ))}
                        </List>
                    )}
                </Box>
            </FocusContext.Provider>

            <Dialog
                open={openDialog}
                onClose={closeDialog}
                sx={{
                    '& .MuiDialog-paper': {
                        outline: 'none',
                    }
                }}
            >
                <FocusContext.Provider value={dialogFocusKey}>
                    <Box ref={dialogRef}>
                        <DialogTitle>Delete Device</DialogTitle>
                        <DialogContent>
                            Are you sure you want to remove "{deviceToDelete?.deviceName}"?
                        </DialogContent>
                        <DialogActions>
                            <DialogButton
                                onClick={closeDialog}
                                color="primary"
                                focusKey="dialog-cancel-button"
                            >
                                Cancel
                            </DialogButton>
                            <DialogButton
                                onClick={() => handleDelete(deviceToDelete?.id)}
                                color="error"
                                focusKey="dialog-delete-button"
                            >
                                Delete
                            </DialogButton>
                        </DialogActions>
                    </Box>
                </FocusContext.Provider>
            </Dialog>
        </div>
    );
};

export default RemoveDevicePreview;