import { Button } from '@mui/material';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';

export const DialogButton = ({ children, onClick, color, focusKey }) => {
    const { ref, focused } = useFocusable({
        focusKey,
        onEnterPress: onClick,
    });

    return (
        <Button
            ref={ref}
            onClick={onClick}
            color={color}
            sx={{
                backgroundColor: focused ? 'primary.main' : 'inherit',
                color: focused ? 'white' : 'inherit',
                '&:hover': {
                    backgroundColor: focused ? 'primary.dark' : 'inherit',
                }
            }}
        >
            {children}
        </Button>
    );
};