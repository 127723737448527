import React, { useState, useEffect } from 'react';
import {
    Button, Card, CardContent, List, ListItem, ListItemIcon,
    ListItemText, IconButton, Dialog, DialogTitle, DialogContent,
    DialogActions, CircularProgress, Box, Typography
} from '@mui/material';
import { FocusContext, useFocusable, init } from '@noriginmedia/norigin-spatial-navigation';
import { DialogButton } from './dialogButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { OTT_POSTER_TYPE, PLAYER } from '../../../constant';
import { utility } from '../../../utility/utility';

// Initialize spatial navigation
init({
    debug: false,
    visualDebug: false,
});

const TrailerPage = () => {

    const location = useLocation();
    const { trailers = [] } = location.state;

    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const { ref: mainRef, focusKey: mainFocusKey, focusSelf: focusMain } = useFocusable({
        focusable: true,
        saveLastFocusedChild: true,
        trackChildren: true,
        autoRestoreFocus: true,
        isFocusBoundary: true,
        focusKey: "trailers-list",
    });

    const { ref: dialogRef, focusKey: dialogFocusKey, focusSelf: focusDialog } = useFocusable({
        focusable: true,
        saveLastFocusedChild: true,
        trackChildren: true,
        autoRestoreFocus: true,
        isFocusBoundary: true,
        focusKey: "dialog-container",
        preferredChildFocusKey: "dialog-ok-button",
    });

    const navigate = useNavigate();

    useEffect(() => {
        focusMain();
    }, [trailers]);

    useEffect(() => {
        document.addEventListener('keydown', onBack);
        return () => {
            document.removeEventListener('keydown', onBack);
        }
    }, []);

    const onBack = (e) => {
        const { keyCode } = e;

        if (keyCode === PLAYER.VK_BACK_SPACE || keyCode === PLAYER.VK_BACK || keyCode === PLAYER.BACK_LG || keyCode === PLAYER.RETURN) {
            e.preventDefault();
            navigate(-1);
        }
    }

    const TrailerItem = ({ trailer }) => {
        const { ref, focused } = useFocusable({
            focusKey: `trailer-${trailer.SID}`,
            onEnterPress: () => {

                navigate('/liveTv', {
                    state: {
                        asset: {
                            VideoPlaybackUrl: trailer?.Videos?.length > 0 ? (trailer?.Videos[0]?.Url ?? "") : "",
                            Title: trailer?.Title ?? '',
                        }
                    }
                });

            },
            onArrowPress: (direction) => {
                const element = document.getElementById(`trailer-${trailer.SID}`);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "center" });
                }
                return true;
            }
        });

        return (
            <Card id={`trailer-${trailer.id}`} ref={ref} sx={{ mb: 2, backgroundColor: "black", color: "white", transition: 'background-color 0.3s', display: "flex", borderRadius: "15px", border: focused ? "1px solid white" : "none" }}>
                <img src={utility.getPosterUrl(trailer.Posters, OTT_POSTER_TYPE.Landscape).length == 0 ? "https://i0.wp.com/ci3.uchicago.edu/wp-content/uploads/2023/03/placeholder-wire-image-dark.jpg?ssl=1" : utility.getPosterUrl(trailer.Posters, OTT_POSTER_TYPE.Portrait)} alt="Card image cap" style={{ height: "   150px", width: "250px", objectFit: "cover", borderRadius: "15px" }} />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {trailer.Title}
                    </Typography>
                    {trailer?.Videos?.length > 0 && <p style={{ color: "white" }}>
                        {trailer?.Videos[0]?.Description ?? ''}
                    </p>}
                </CardContent>
            </Card>
        );
    };

    return (
        <div style={{ padding: "20px", backgroundColor: "black", color: "white" }} className="ContentWrapper">
            <FocusContext.Provider value={mainFocusKey}>
                <Box ref={mainRef}>
                    <Typography variant="h4" component="h1" gutterBottom style={{ display: "flex" }}>
                        Trailers
                    </Typography>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                            <CircularProgress />
                        </Box>
                    ) : trailers.length === 0 ? (
                        <Box textAlign="center" mt={30}>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "10px" }}>
                                <div style={{ width: 80, height: 80, backgroundColor: '#f4511e', color: "white", borderRadius: '50%', display: "flex", justifyContent: "center", alignItems: "center" }} className='fa fa-star fa-3x' />
                            </div>
                            <Typography variant="h6" color="text.light">No trailers found</Typography>
                        </Box>
                    ) : (
                        <List>
                            {trailers.map((trailer) => (
                                <TrailerItem key={trailer.SID} trailer={trailer} />
                            ))}
                        </List>
                    )}
                </Box>
            </FocusContext.Provider>
        </div>
    );
};

export default TrailerPage;