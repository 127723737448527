import './App.css';
import { Route, Routes } from 'react-router-dom'
import LiveTv from './tv/LiveTv/LiveTv';
import { init } from '@noriginmedia/norigin-spatial-navigation';
import Preview from './tv/homePage/components/Preview';
import PiPage from './tv/homePage/components/PiPage';
import Login from './tv/login/login';
import SearchPage from './tv/homePage/search/searchPage';
import Epg from './tv/homePage/epg/epg';
import RemoveDevicePreview from './tv/homePage/components/removeDevice';
import PlanPage from './tv/homePage/components/planPage';
import TrailerPage from './tv/homePage/components/TrailerPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {

  init({
    throttleKeypresses: true,
    throttle: 100
  });

  return <>
    <ToastContainer />
    <Routes>
      <Route path='/' element={<Preview />} />
      <Route path='/mobile' element={<Preview />} />
      <Route path='/login' element={<Login />} />
      <Route path='/search' element={<SearchPage />} />
      <Route path='/liveTv' element={<LiveTv />} />
      <Route path='/epg' element={<Epg />} />
      <Route path='/removeDevice' element={<RemoveDevicePreview />} />
      <Route path='/plans' element={<PlanPage />} />
      <Route path='/trailers' element={<TrailerPage />} />
      <Route path='/:title' element={<PiPage />} />
    </Routes>
  </>
}

export default App;


