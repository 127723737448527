import React, { useState, useEffect } from 'react';
import {
    Button, Card, CardContent, List, ListItem, ListItemIcon,
    ListItemText, IconButton, Dialog, DialogTitle, DialogContent,
    DialogActions, CircularProgress, Box, Typography
} from '@mui/material';
import { FocusContext, useFocusable, init } from '@noriginmedia/norigin-spatial-navigation';
import { DialogButton } from './dialogButton';
import { useNavigate } from 'react-router-dom';
import { getPlansByRegion } from '../../../Api/api';
import { PLAYER } from '../../../constant';

// Initialize spatial navigation
init({
    debug: false,
    visualDebug: false,
});

const PlanPage = () => {

    const [plans, setPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const { ref: mainRef, focusKey: mainFocusKey, focusSelf: focusMain } = useFocusable({
        focusable: true,
        saveLastFocusedChild: true,
        trackChildren: true,
        autoRestoreFocus: true,
        isFocusBoundary: true,
        focusKey: "plans-list",
    });

    const { ref: dialogRef, focusKey: dialogFocusKey, focusSelf: focusDialog } = useFocusable({
        focusable: true,
        saveLastFocusedChild: true,
        trackChildren: true,
        autoRestoreFocus: true,
        isFocusBoundary: true,
        focusKey: "dialog-container",
        preferredChildFocusKey: "dialog-ok-button",
    });

    const navigate = useNavigate();

    useEffect(() => {
        loadPlans();
    }, []);

    useEffect(() => {
        if (!openDialog) {
            focusMain();
        } else {
            focusDialog();
        }
    }, [openDialog, plans]);

    useEffect(() => {
        document.addEventListener('keydown', onBack);
        return () => {
            document.removeEventListener('keydown', onBack);
        }
    }, []);

    const loadPlans = async () => {
        setIsLoading(true);
        let res = await getPlansByRegion();
        console.log(res);
        if (res.success) {
            setPlans(res.data.map((d, i) => {
                return {
                    id: i,
                    ...d
                }
            }));
        } else {
            setPlans([]);
            console.log(res);
        }
        setIsLoading(false);
    };

    const onBack = (e) => {
        const { keyCode } = e;

        if (keyCode === PLAYER.VK_BACK_SPACE || keyCode === PLAYER.VK_BACK || keyCode === PLAYER.BACK_LG || keyCode === PLAYER.RETURN) {
            e.preventDefault();
            navigate(-1);
        }
    }

    const confirmPlan = (device) => {
        setOpenDialog(true);
        focusDialog();
    };

    const closeDialog = () => {
        setOpenDialog(false);
        focusMain();
    };

    const DeviceItem = ({ device }) => {
        const { ref, focused } = useFocusable({
            focusKey: `device-${device.id}`,
            onEnterPress: () => confirmPlan(device),
            onArrowPress: (direction) => {
                const element = document.getElementById(`device-${device.id}`);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "center" });
                }
                return true;
            }
        });

        return (
            <Card id={`device-${device.id}`} ref={ref} sx={{ mb: 2, backgroundColor: focused ? 'white' : '#e2eaee', transition: 'background-color 0.3s' }}>
                <CardContent>
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <div style={{ width: 50, height: 50, backgroundColor: '#f4511e', color: "white", borderRadius: '50%', marginRight: "10px", display: "flex", justifyContent: "center", alignItems: "center" }} className='fa fa-star fa-2x' />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography variant="h5" style={{ position: "relative", top: "10px" }}>{device.Description}</Typography>}
                            secondary={<div style={{ position: "relative", top: "10px" }}><i className='fa fa-check fa-lg' style={{ color: 'green' }} /><p style={{ display: "inline-block", paddingLeft: "5px" }}>Device Limit: {device.deviceLimit} Devices</p></div>}
                        />
                        <Box display="flex" alignItems="center">
                            <p style={{ fontWeight: "bold", fontSize: "24px" }}>₹{device.Amount} </p><p style={{ fontWeight: "bold", fontSize: "14px" }}> /{device.OttPeriodDays} days</p>
                        </Box>
                    </ListItem>
                </CardContent>
            </Card>
        );
    };

    return (
        <div style={{ padding: "20px", backgroundColor: "black", color: "white" }} className="ContentWrapper">
            <FocusContext.Provider value={mainFocusKey}>
                <Box ref={mainRef}>
                    <Typography variant="h4" component="h1" gutterBottom style={{ display: "flex" }}>
                        Choose Plan
                    </Typography>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                            <CircularProgress />
                        </Box>
                    ) : plans.length === 0 ? (
                        <Box textAlign="center" mt={30}>
                            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "10px" }}>
                                <div style={{ width: 80, height: 80, backgroundColor: '#f4511e', color: "white", borderRadius: '50%', display: "flex", justifyContent: "center", alignItems: "center" }} className='fa fa-star fa-3x' />
                            </div>
                            <Typography variant="h6" color="text.light">No plans found</Typography>
                        </Box>
                    ) : (
                        <List>
                            {plans.map((device) => (
                                <DeviceItem key={device.id} device={device} />
                            ))}
                        </List>
                    )}
                </Box>
            </FocusContext.Provider>

            <Dialog
                open={openDialog}
                onClose={closeDialog}
                sx={{
                    '& .MuiDialog-paper': {
                        outline: 'none',
                    }
                }}
            >
                <FocusContext.Provider value={dialogFocusKey}>
                    <Box ref={dialogRef}>
                        <DialogTitle>Complete Payment</DialogTitle>
                        <DialogContent>
                            Please complete payment on your mobile device
                        </DialogContent>
                        <DialogActions>
                            <DialogButton
                                onClick={() => navigate(-1)}
                                focusKey="dialog-ok-button"
                            >
                                OK
                            </DialogButton>
                        </DialogActions>
                    </Box>
                </FocusContext.Provider>
            </Dialog>
        </div>
    );
};

export default PlanPage;