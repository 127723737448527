import { FocusContext, useFocusable, init } from '@noriginmedia/norigin-spatial-navigation';
import React, { useEffect, useRef, useState } from 'react';
import { DEVICE_ACCESS_CONTROL, LOCAL_STORAGE_KEY, PLAYER } from '../../constant';
import { addDevice, getSubscriber, sendOTP } from '../../Api/api';
import { useNavigate } from 'react-router-dom';
import { utility } from '../../utility/utility';
import {
    Button, Card, CardContent, List, ListItem, ListItemIcon,
    ListItemText, IconButton, Dialog, DialogTitle, DialogContent,
    DialogActions, CircularProgress, Box, Typography, ThemeProvider, createTheme
} from '@mui/material';
import { DialogButton } from '../homePage/components/dialogButton';

const LoginRightSide = ({ mobileNumber, setShowVerifyOTP, showVerifyOTP, setMobileNumber, ottSetting }) => {
    const otpRef = useRef();
    const userRef = useRef();
    const navigate = useNavigate();

    const { ref: dialogRef, focusKey: dialogFocusKey, focusSelf: focusDialog } = useFocusable({
        focusable: true,
        saveLastFocusedChild: true,
        trackChildren: true,
        autoRestoreFocus: true,
        isFocusBoundary: true,
        focusKey: "dialog-container",
        preferredChildFocusKey: "dialog-cancel-button",
    });

    const { ref, focused, focusSelf: focusMain } = useFocusable({
        onArrowPress: (direction) => {
            if (direction === 'left') {
                return true;
            }
            return false;
        },
        onEnterRelease: async () => {
            await onLoginEnter();
        }
    });

    useEffect(() => {
        document.addEventListener('keydown', onBack);
        return () => {
            document.removeEventListener('keydown', onBack);
        }
    }, []);

    const [message, setMessage] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [onMouseFocus, setOnMouseFocus] = React.useState(false);

    const onLoginEnter = async () => {
        if (isLoading) return;
        if (!showVerifyOTP) {
            let isValidEmail = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/.test(mobileNumber);
            let isValidMobile = /^\d{10}$/.test(mobileNumber);

            if (!isValidEmail && !isValidMobile) {
                setMessage('Please enter valid email or mobile number');
                return;
            }

            setMessage('');
            let subscriber = await getSubscriber(mobileNumber);
            if (subscriber.success) {
                userRef.current = subscriber.data;
                setIsLoading(true);
                let otpRes = await sendOTP(subscriber.data);
                setIsLoading(false);
                if (otpRes.success) {
                    otpRef.current = otpRes.data.otp.toString();
                    setMobileNumber('');
                    setShowVerifyOTP(true);
                } else {
                    setMessage(otpRes.message);
                    return;
                }
            } else {
                setMessage(subscriber.message);
                return
            }

        } else {
            if (mobileNumber.length === 4) {
                setMessage('');
                if (otpRef.current == mobileNumber) {
                    // otp verified
                    setIsLoading(true);
                    let addDeviceRes = await addDevice(userRef.current._id, (userRef.current.Name + '`s Device'));
                    setIsLoading(false);


                    if (!addDeviceRes.success) {
                        setDialogMessage(addDeviceRes.message);
                        focusDialog();
                        return;
                    }
                    utility.setValue(LOCAL_STORAGE_KEY.user, userRef.current);
                    navigate('/');
                } else {
                    setMessage('Please enter valid OTP');
                }
            } else {
                setMessage('Please enter all 4 digits');
            }
        }
    }

    const onBack = (e) => {
        const { keyCode } = e;

        if (keyCode === PLAYER.VK_BACK_SPACE || keyCode === PLAYER.VK_BACK || keyCode === PLAYER.BACK_LG || keyCode === PLAYER.RETURN) {
            e.preventDefault();
            if (showVerifyOTP) {
                setMobileNumber('');
                setShowVerifyOTP(false);
            } else {
                navigate(-1);
            }
        }
    }

    const closeDialog = () => {
        setDialogMessage('');
        setTimeout(() => {
            focusMain();
        }, 500);
    };

    return (
        <>
            <FocusContext.Provider >
                <div className='loginInputOuterDiv'>
                    {showVerifyOTP ?
                        <ul className='otpOuterDiv'>
                            <li>{mobileNumber[0] ?? ""}</li>
                            <li>{mobileNumber[1] ?? ""}</li>
                            <li>{mobileNumber[2] ?? ""}</li>
                            <li>{mobileNumber[3] ?? ""}</li>
                        </ul>
                        : <input disabled={true} type={showVerifyOTP ? "number" : "text"} value={mobileNumber} className='loginInput' placeholder="Enter email or mobile number" />}
                    <button className={`loginButton ${(focused || onMouseFocus) && 'active'}`} ref={ref} onClick={onLoginEnter} onMouseEnter={() => setOnMouseFocus(true)}
                        onMouseLeave={() => setOnMouseFocus(false)}>{isLoading ? <i className="fa fa-spinner fa-spin fa-x" aria-hidden="true" /> : (showVerifyOTP ? "Verify OTP" : "Login")}</button>
                    {message.length > 0 && <div style={{
                        color: 'red',
                        marginTop: '10px'
                    }}>{message}</div>}
                </div>
            </FocusContext.Provider>
            {(dialogMessage.length > 0) && <Dialog
                open={true}
                onClose={closeDialog}
                sx={{
                    '& .MuiDialog-paper': {
                        outline: 'none',
                    }
                }}
            >
                <FocusContext.Provider value={dialogFocusKey}>
                    <Box ref={dialogRef}>
                        <DialogTitle>Delete Device</DialogTitle>
                        <DialogContent>
                            {dialogMessage}
                        </DialogContent>
                        <DialogActions>
                            <DialogButton
                                onClick={closeDialog}
                                color="primary"
                                focusKey="dialog-cancel-button"
                            >
                                Cancel
                            </DialogButton>
                            <DialogButton
                                onClick={() => {
                                    navigate('/removeDevice', { state: { user: userRef.current, fromLogin: true } });
                                }}
                                color="error"
                                focusKey="dialog-delete-button"
                            >
                                Delete
                            </DialogButton>
                        </DialogActions>
                    </Box>
                </FocusContext.Provider>
            </Dialog>
            }
        </>
    );
}

export default LoginRightSide;
