import React, { useState } from 'react';
import Menu from './homePage/menu/menu';
import MainPage from './MainPage';
import Epg from './homePage/epg/epg';

const MainWrapper = ({ focusKeyParam,
    page, ottSetting, menu, isMobile }) => {

    const [currentPage, setCurrentPage] = useState(page);

    const renderContent = () => {
        switch (currentPage?._id) {
            case 'epg':
                return (
                    <Epg
                        focusKeyParam={"epg"}
                        page={currentPage}
                        ottSetting={ottSetting}
                        isMobile={isMobile}
                    />
                );
            default:
                return (
                    <MainPage
                        setCurrentPage={setCurrentPage}
                        focusKeyParam={focusKeyParam}
                        page={currentPage}
                        ottSetting={ottSetting}
                        isMobile={isMobile}
                    />
                );
        }
    };

    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
        }}>
            {!isMobile && <Menu focusKey={"MENU"} ottSetting={ottSetting} menu={menu} setCurrentPage={setCurrentPage} currentPage={currentPage} />}
            {
                renderContent()
            }
        </div>
    );
}

export default MainWrapper;
