import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { utility } from '../../../utility/utility';
import { OTT_POSTER_TYPE, OTT_VIDEO_TYPE } from '../../../constant';
import Player from '../../LiveTv/player/Player';

const PiPageTop = ({ ottAsset, theme, showPlayButton = true, ottSetting, isMobile }) => {
    const [image, setImage] = useState(ottAsset?.Posters ? utility.getPosterUrl(ottAsset?.Posters, OTT_POSTER_TYPE.Landscape) : '');
    useEffect(() => {
        // Update the image URL when ottAsset changes
        setImage(ottAsset?.Posters ? utility.getPosterUrl(ottAsset?.Posters, OTT_POSTER_TYPE.Landscape) : '');
    }, [ottAsset]);
    console.log(ottAsset);
    const piPageSetting = ottSetting?.piPageSetting;
    //     Video? trailerVideo = ottAsset.videos
    //     ?.where((v) => v.ottVideoType!.sid == OTT_VIDEO_TYPE.TRAILER)
    //     .firstOrNull;
    // bool canPlayTrailer = trailerVideo != null;
    const trailerVideo = ottAsset?.Videos?.filter((v) => v.OttVideoType.SID == OTT_VIDEO_TYPE.TRAILER) ?? [];
    const canPlayTrailer = trailerVideo.length > 0;
    return (
        <div className='pi-page' style={{ backgroundColor: theme?.bgColor ?? "black" }} >
            {!isMobile &&
                <div style={{
                    position: showPlayButton ? 'fixed' : 'absolute', top: 0, right: 0, width: "100%", height: "100%",
                    overflow: "hidden",
                    zIndex: -99
                }}>{canPlayTrailer ? <Player src={trailerVideo[0].Url} asset={ottAsset} showControls={false} /> :
                    <div style={{ backgroundImage: `url(${image})`, height: "100%", width: "100%", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}> </div>}
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 70%)",
                        pointerEvents: "none" // Ensures overlay doesn’t interfere with controls if enabled
                    }}></div>
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "linear-gradient(to top, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 70%)",
                        pointerEvents: "none" // Ensures overlay doesn’t interfere with controls if enabled
                    }}></div>
                </div>}

            <div className='pi-page-main' style={{ color: theme.color, zIndex: 100000 }}>
                {/* media category example movie */}
                <p className='type'>{utility.getMediaCategoryType(ottAsset?.MediaCategory ?? 0)}</p>
                {/* title */}
                <p className='title'>{((piPageSetting?.provider ?? true) && ottAsset?.Provider?.LogoUrl) && <img src={ottAsset?.Provider?.LogoUrl ?? 'https://res.cloudinary.com/dl2n34gbw/image/upload/v1707321508/gswygsxknleqqxjxqyrt.jpg'} style={{ width: "30px", height: "30px", borderRadius: "50%", objectFit: "cover", backgroundColor: "white" }} />} {ottAsset?.Title ?? ""}</p>
                {/* genre action, thriller */}
                {((piPageSetting?.genre ?? true) && ottAsset?.Genres?.length != 0) && <span className='genre'>{ottAsset?.Genres?.toString() ?? ""}</span>}
                {/* release date 2024 */}
                {((piPageSetting?.releaseDate ?? true) && ottAsset?.ReleaseDate.toString().length > 0 && ottAsset?.ReleaseDate != 0) && <span className='year'>{new Date(ottAsset?.ReleaseDate.toString().length > 0 ? ottAsset?.ReleaseDate : 0).getFullYear()}</span>}
                {/* pg rating PG18 */}
                {((piPageSetting?.pgRating ?? true) && ottAsset?.PGRating != 'N/A' && ottAsset?.PGRating?.toString().length != 0) && <span className='rating'>{ottAsset?.PGRating ?? ""}</span>}
                {/* duration 1h 30m */}
                {ottAsset?.Duration != 0 && <span className='duration'>{new Date(ottAsset?.Duration ?? 0).getUTCHours()}h {new Date(ottAsset?.Duration ?? 0).getUTCMinutes()}m</span>}
                {/* description */}
                {(piPageSetting?.description ?? true) && <p style={{ marginTop: showPlayButton ? "60px" : 0 }} className='description'>{ottAsset?.MediaEpisodeDescription?.length > 0 ? ottAsset?.MediaEpisodeDescription : ottAsset?.Description ?? ""}</p>}
                {/* cast & crew */}
                {((piPageSetting?.genre ?? true) && ottAsset?.CastAndCrew?.length != 0) && <span className='description' style={{
                    opacity: 0.8, fontSize: "12px"
                }}>{ottAsset?.CastAndCrew.map(c => `${c.CastType} : ${c.Name}`)?.toString().substring(0, 100) ?? ""}</span>}
                {/* languages hindi, english */}
                {((piPageSetting?.genre ?? true) && ottAsset?.Languages?.length != 0) && <span className='description' style={{
                    opacity: 0.9, fontSize: "12px"
                }}><i className='fa fa-bullhorn' /> {ottAsset?.Languages?.toString().substring(0, 100) ?? ""}</span>}

            </div>
        </div>
    );
}

export default PiPageTop;
