import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSocket from '../../../socket/useSocket';
import { LOCAL_STORAGE_KEY, MODULE, NAVDIRECTION, OTTROUTETYPE, OTT_POSTER_TYPE, PLAYER, RAILTYPE, SOCKET_ACTION, SOCKET_EVENTS, VODTYPE } from '../../../constant';
import { getAssetByID, getMoreMoviesLikeThis, getOttSettings } from '../../../Api/api';
import { utility } from '../../../utility/utility';
import ImageCard from './ImageCard';
import PiPageTop from './PiPageTop';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import RailComponent from './RailComponent';
import { startTransition } from 'react';

const PiPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { asset, isMobile = false } = location.state;
    const [ottSetting, setOttSetting] = useState(location.state.ottSetting);
    const [ottAsset, setOttAsset] = useState(asset);
    const [moreMoviesLikeThis, setMoreMoviesLikeThis] = useState([]);
    const [image, setImage] = useState(ottAsset?.Posters ? utility.getPosterUrl(ottAsset?.Posters, OTT_POSTER_TYPE.Landscape) : '');
    const { ref, focusKey, focusSelf, focused } = useFocusable({
        focusable: true,
        trackChildren: true,
        autoRestoreFocus: true,
        onFocus: () => { onMouseEnter(null) },
        onBlur: () => { onMouseOut(null) },
        onEnterPress: () => {
            onClick();
        }
    });
    const { ref: trailerRef, focusKey: trailerFocusKey, focusSelf: trailerFocusSelf, focused: trailerFocused } = useFocusable({
        focusable: true,
        trackChildren: true,
        autoRestoreFocus: true,
        onFocus: () => { onMouseEnter(null, true) },
        onBlur: () => { onMouseOut(null, true) },
        onEnterPress: () => {
            onTrailerClick(ottAsset);
        }
    });
    // const {
    //     ref,
    //     focusSelf,
    //     hasFocusedChild,
    //     focusKey = "pi-page",
    //     setFocus,
    //     // navigateByDirection, -- to manually navigate by direction
    //     // pause, -- to pause all navigation events
    //     // resume, -- to resume all navigation events
    //     // updateAllLayouts, -- to force update all layouts when needed
    //     // getCurrentFocusKey -- to get the current focus key
    // } = useFocusable({
    //     focusable: true,
    //     trackChildren: true,
    //     focusKey: "pi-page",
    //     onEnterRelease: () => { alert('enter') },
    //     onFocus: () => onMouseEnter,
    //     onBlur: () => onMouseOut,
    //     extraProps: { foo: 'bar' }
    // });

    useEffect(() => {
        document.getElementById('pi-page-main').scrollIntoView(0, 0);
    }, []);

    useEffect(() => {
        focusSelf();

    }, [focusSelf]);

    // useEffect(() => {
    //     setFocus("pi-page")

    // }, [setFocus]);

    useEffect(() => {
        document.addEventListener('keydown', onKeyPress);
        // setFocus("pi-page");
        return () => document.removeEventListener('keydown', onKeyPress);
    }, []);

    useEffect(() => {
        loadOttAsset();
    }, [asset]);

    useEffect(() => {
        setOttAsset(asset);
        setImage(asset?.Posters ? utility.getPosterUrl(asset?.Posters, OTT_POSTER_TYPE.Landscape) : '');
    }, [asset]);


    const socket = useSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

        if (socketData.module != MODULE.PLATFORM) return;

        switch (socketData.action) {
            case SOCKET_ACTION.PLATFORM_THEME_REFRESH:
                loadOttSettings();
                break;
            default:
                break;
        }
    });

    const onKeyPress = useCallback(({ keyCode }) => {

        if (keyCode === PLAYER.VK_BACK_SPACE || keyCode === PLAYER.BACK_LG || keyCode === PLAYER.RETURN) {
            navigate(-1)
        }

    });

    const onRowFocus = useCallback(({ y, top, ...rest }) => {

        console.log("calling row focus " + y);
        console.log(top);
        console.log(window.scrollY);

        // ref.current?.scrollTo({
        //     top: top,
        //     behavior: 'smooth'
        // })

        document.getElementById('contentDiv').scrollTo({
            top: top - window.innerHeight / 2,
            behavior: "smooth",
        });

    }, [ref]);

    var theme = {
        bgColor: ottSetting?.themeData?.general?.bgColor ?? "black",
        color: ottSetting?.themeData?.general?.color ?? "white",
        primaryColor: {
            bgColor: ottSetting?.themeData?.primary?.bgColor,
            color: ottSetting?.themeData?.primary?.color,
        },
        secondaryColor: {
            bgColor: ottSetting?.themeData?.secondary?.bgColor,
            color: ottSetting?.themeData?.secondary?.color,
        }


    }

    const loadOttAsset = async () => {
        const ottAssetRes = await getAssetByID(asset._id);
        if (ottAssetRes.success) {
            console.log(ottAssetRes);
            setOttAsset(ottAssetRes.data);
            setImage(ottAssetRes.data?.Posters ? utility.getPosterUrl(ottAssetRes.data?.Posters, OTT_POSTER_TYPE.Landscape) : '');
            loadMoreMoviesLikeThis(ottAssetRes.data);
        }
    }

    const loadOttSettings = async () => {
        const ottSettingRes = await getOttSettings();
        console.log(ottSettingRes);
        if (ottSettingRes.success) {
            console.log(ottSettingRes);
            setOttSetting({ ...ottSettingRes.data, piPageSetting: ottSettingRes?.piPageSetting });
        }
    }

    const loadMoreMoviesLikeThis = async (a) => {
        const res = await getMoreMoviesLikeThis(a.Genres[0]);
        if (res.success) {
            console.log(res);
            setMoreMoviesLikeThis(res.data);
        }
    }
    const onMouseEnter = (e, isTrailer = false) => {
        if (e) e?.preventDefault();
        // change border color
        let playButtonDiv = document.getElementsByClassName(isTrailer ? 'trailer-button' : 'play-button')[0];
        if (playButtonDiv) {
            playButtonDiv.style.borderColor = theme.secondaryColor.bgColor;
            playButtonDiv.style.color = theme.secondaryColor.bgColor;
            playButtonDiv.style.backgroundColor = theme.primaryColor.bgColor;
        }
    }

    const onMouseOut = (e, isTrailer = false) => {
        e?.preventDefault();
        // change border color
        let playButtonDiv = document.getElementsByClassName(isTrailer ? 'trailer-button' : 'play-button')[0];
        if (playButtonDiv) {
            playButtonDiv.style.borderColor = theme.primaryColor.bgColor;
            playButtonDiv.style.color = theme.primaryColor.bgColor;
            playButtonDiv.style.backgroundColor = theme.secondaryColor.bgColor;
        }
    }

    const onClick = () => {
        navigate('/liveTv', { state: { asset: ottAsset } });
    }

    const onTrailerClick = (asset) => {
        if (asset.Trailers.length == 1) {
            let trailer = asset.Trailers[0];
            navigate('/liveTv', {
                state: {
                    asset: {
                        VideoPlaybackUrl: trailer?.Videos[0]?.Url ?? "",
                        Title: trailer?.Title ?? '',
                    }
                }
            });
        } else {
            navigate('/trailers', {
                state: {
                    trailers: asset.Trailers
                }
            });
        }
    }

    var fontWeight = ottSetting?.themeData?.rail?.fontWeight;
    const isTrailerAvailable = (ottAsset.Trailers?.length ?? 0) > 0;
    return (
        <div id='pi-page-main' style={{ height: "100vh", width: "100vw", overflowX: "hidden" }} >
            <div style={{ height: "auto", width: "100%", position: "relative" }}>
                {isMobile && <div style={{ backgroundImage: `url(${image})`, height: "250px", width: "100%", backgroundSize: "cover", position: "relative" }}>
                    <i onClick={() => { onClick() }} className='fa fa-play-circle' style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: "50px", color: theme.primaryColor.bgColor, cursor: "pointer" }} />
                </div>}
                <div style={{ position: "relative", top: isMobile ? -45 : 0 }}>
                    <PiPageTop isMobile={isMobile} ottSetting={ottSetting} ottAsset={ottAsset} theme={theme} />
                </div>
                <FocusContext.Provider value={focusKey}>
                    <div ref={ref} className='play-button' onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} style={{ border: `1px solid ${theme.primaryColor.bgColor}`, color: theme.primaryColor.bgColor, backgroundColor: theme.secondaryColor.bgColor, position: "absolute", left: 50, top: isMobile ? 350 : 150, width: isTrailerAvailable ? "200px" : "400px", height: "35px", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => { onClick() }}><i className='fa fa-play' /> Play</div>
                </FocusContext.Provider>
                {isTrailerAvailable && <FocusContext.Provider value={trailerFocusKey}>
                    <div ref={trailerRef} className='play-button trailer-button' onMouseEnter={(e) => onMouseEnter(e, true)} onMouseOut={(e) => onMouseOut(e, true)} style={{ border: `1px solid ${theme.primaryColor.bgColor}`, color: theme.primaryColor.bgColor, backgroundColor: theme.secondaryColor.bgColor, position: "absolute", left: 270, top: isMobile ? 350 : 150, width: "200px", height: "35px", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => { onTrailerClick(asset) }}><i className='fa fa-play' /> Trailer</div>
                </FocusContext.Provider>}
            </div>
            {isMobile && <div style={{ padding: "10px", position: "relative", top: "-50px" }}>
                <div className="m-2 textColor" >
                    <p style={{ fontWeight: fontWeight < 4 ? "lighter" : fontWeight < 6 ? "normal" : fontWeight < 8 ? "bold" : "bolder", color: ottSetting?.themeData?.rail?.color ?? "white", fontFamily: ottSetting?.themeData?.rail?.fontFamily ?? "Roboto", fontSize: ottSetting?.themeData?.rail?.fontSize + "px" }} className="blockquote">{'More Movies Like This'}</p>
                </div>
                {
                    moreMoviesLikeThis?.map((asset, index) => {
                        let imgUrl = utility.getPosterUrl(asset?.Posters, OTT_POSTER_TYPE.Landscape);
                        return <div style={
                            { margin: "15px 10px", display: "flex", cursor: "pointer" }
                        } onClick={() => { onClick() }} >
                            <div style={{ backgroundImage: `url(${imgUrl})`, height: "100px", width: "200px", minWidth: "200px", backgroundSize: "cover", borderRadius: "5px", marginRight: "5px" }}> </div>
                            <div>
                                <div style={{ color: ottSetting.themeData?.rail?.color }}>{asset?.Title}</div>
                                <div style={{ color: ottSetting.themeData?.rail?.color, opacity: "0.7", fontSize: "15px" }}>{asset?.MediaEpisodeDescription?.toString().length > 70 ? asset?.MediaEpisodeDescription?.toString().substring(0, 70) + "..." : asset?.MediaEpisodeDescription?.toString().substring(0, 70) ?? ""}</div>
                            </div>
                        </div>
                    })
                }

            </div>}
            {!isMobile && <div id='contentDiv' style={{ position: "absolute", left: 0, right: 0, top: isMobile ? "70%" : "60%", padding: "0 10px" }}>
                <div className="m-2 textColor" >
                    <p style={{ fontWeight: fontWeight < 4 ? "lighter" : fontWeight < 6 ? "normal" : fontWeight < 8 ? "bold" : "bolder", color: ottSetting?.themeData?.rail?.color ?? "white", fontFamily: ottSetting?.themeData?.rail?.fontFamily ?? "Roboto", fontSize: ottSetting?.themeData?.rail?.fontSize + "px" }} className="blockquote">{'More Movies Like This'}</p>
                </div>
                <RailComponent setOttAsset={setOttAsset} ottSetting={ottSetting} rail={{ OttAsset: moreMoviesLikeThis }} onFocus={onRowFocus} index={0} key={20 + "-" + 10} rowIndex={"Rail" + 0} railType={RAILTYPE.Rail} />
            </div>}
        </div >

    );
}

export default PiPage;
