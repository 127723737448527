import React, { useState } from 'react';
import { utility } from '../../../utility/utility';
import { LOCAL_STORAGE_KEY, OTTROUTETYPE, OTT_POSTER_TYPE, VODTYPE } from '../../../constant';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useNavigate } from 'react-router-dom';
import crown from '../../../images/crown.png';

const AssetCard = ({ index, ottSetting, ottAsset, positionTop, setPositionTop, timeout }) => {

    const navigate = useNavigate();

    const isFree = ottAsset.VodTypes?.SID == VODTYPE.FREE;
    const canPlay = isFree || utility.getValue(LOCAL_STORAGE_KEY.user)?.PaymentPlan?.length > 0 ? true : false ?? false;
    const [onMouseFocus, setOnMouseFocus] = useState(false);

    const { ref, focused } = useFocusable({
        focusKey: index,
        onEnterRelease: () => {
            onClick();
        },
        onFocus: ({ y, ...rest }) => {
            document.getElementById('searchRightSideContainer')?.scrollTo({
                top: y - 15,
                behavior: "smooth",
            });

            if (ref.current.getBoundingClientRect().y < 115) {
                setPositionTop(106)
            } else {
                if (timeout) {
                    clearTimeout(timeout);
                } else {
                    timeout = setTimeout(() => {
                        setPositionTop(ref.current.getBoundingClientRect().y);
                    }, 700);
                }
            }

        }

    });

    const onClick = () => {
        utility.onAssetClick(ottAsset, ottSetting, navigate);
    }

    return <FocusContext.Provider value='search'>
        {(focused || onMouseFocus) && positionTop != 0 && <div style={{ cursor: "pointer" }}> <img src={utility.getPosterUrl(ottAsset.Posters, OTT_POSTER_TYPE.Landscape)} style={{ borderRadius: "10px", height: "100%", left: "40%", top: 0, width: "60%", right: "0", display: "flex", position: "fixed", transition: "all 0.23s ease-in-out", filter: "blur(100px)" }} />
        </div>
        }
        <div ref={ref} style={{ borderRadius: "10px", marginBottom: "20px", marginRight: "20px", transition: "all 0.23s linear", display: "flex", position: "relative", border: `3px solid ${(focused || onMouseFocus) ? 'white' : 'transparent'}`, zIndex: 10000, cursor: "pointer" }} key={ottAsset._id} onMouseEnter={() => setOnMouseFocus(true)}
            onMouseLeave={() => setOnMouseFocus(false)}
            onClick={onClick}
        >
            <img style={{ height: "180px", width: "300px", borderRadius: "10px", objectFit: "cover" }} src={utility.getPosterUrl(ottAsset.Posters, OTT_POSTER_TYPE.Landscape)} />
            {!isFree && <img style={{ height: "30px", width: "30px", position: "absolute", top: "5px", left: "318px", zIndex: "10000" }} src={crown} />}
            <div style={{ marginLeft: "20px", marginTop: "30px", color: "white" }}>
                <p style={{ fontSize: "larger", marginBottom: "2px" }}>{ottAsset.Title}</p>
                <p style={{ fontSize: "medium", marginBottom: "2px" }}>{utility.formatMilliseconds(ottAsset.Duration)}</p>
                <p style={{ fontSize: "medium", }}>{ottAsset.Description}</p>
            </div>
        </div>
    </FocusContext.Provider>
}

export default AssetCard;
