import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React from 'react';

const FullKeypadNum = ({ key, numValue, index, setSearchKey, setShowNumPad = () => { } }) => {

    const [onMouseFocus, setOnMouseFocus] = React.useState(false);

    const { ref, focused } = useFocusable({
        focusKey: index,
        onArrowPress: (direction) => {
            if (direction === 'left' && ('agmsy5@'.includes(numValue) || index == 100)) {
                return false;
            }
            if (direction === 'up' && (index == 100 || index == 101)) {
                return false;
            } else {
                return true;
            }
        },
        onEnterRelease: () => {
            onEnterPress();
        }

    });

    const onEnterPress = () => {
        if (typeof (numValue) == 'object') {
            if (numValue.props.children == 'backspace') {
                setSearchKey(searchKey => searchKey.slice(0, -1));
            } else if (numValue.props.children == 'clear') {
                setSearchKey('');
            } else {
                setSearchKey(searchKey => searchKey + ' ');
            }
        }
        else {
            if (numValue == '?123') {
                setShowNumPad(true);
            } else {
                setSearchKey(searchKey => searchKey + numValue);
            }
        }
    };
    return (
        <li
            ref={ref}
            key={key}
            onClick={onEnterPress}
            onMouseEnter={() => setOnMouseFocus(true)}
            onMouseLeave={() => setOnMouseFocus(false)}
            className={`letter ${index % 6 === 0 ? 'clearl' : ''} ${(focused || onMouseFocus) ? 'active' : ''} ${typeof (numValue) == 'object' ? 'expand' : ''}`}
        > {numValue}
        </li>
    );
}

export default FullKeypadNum;
