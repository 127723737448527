import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React from 'react';

const KeypadNum = ({ key, numValue, index, setMobileNumber, showVerifyOTP, setShowNumPad = () => { } }) => {
    const { ref, focused } = useFocusable({
        focusKey: key,
        onArrowPress: (direction) => {
            if (direction === 'up' && (numValue == '1' || numValue == '2' || numValue == '3')) {
                return false;
            }
            if (direction === 'left' && (numValue == '1' || numValue == '4' || numValue == '7' || numValue == '←')) {
                return false;
            }
            return true;
        },
        onEnterRelease: () => onNumberClick()

    });

    const [onMouseFocus, setOnMouseFocus] = React.useState(false);

    const onNumberClick = () => {
        if (numValue === 'ABC') {
            setShowNumPad(false);
        }
        else if (numValue === 'x') {
            setMobileNumber('');
        } else if (numValue === '←') {
            setMobileNumber(mobileNumber => mobileNumber.slice(0, -1));
        } else {
            setMobileNumber(mobileNumber => {
                if (!showVerifyOTP && mobileNumber.length == 0 && numValue == '1') {
                    return mobileNumber;
                }
                if (showVerifyOTP && mobileNumber.length == 4) {
                    return mobileNumber;
                } else if (!showVerifyOTP && mobileNumber.length == 10) {
                    return mobileNumber;
                }
                return mobileNumber + numValue
            });
        }
    };

    return (
        <li
            onClick={onNumberClick}
            ref={ref}
            key={key}
            onMouseEnter={() => setOnMouseFocus(true)}
            onMouseLeave={() => setOnMouseFocus(false)}
            className={`letter ${index % 3 === 0 && index !== 0 ? 'clearl' : ''} ${(focused || onMouseFocus) ? 'active' : ''}`}
        > {numValue}
        </li>
    );
}

export default KeypadNum;
