import { useLocation } from "react-router-dom"
import Player from "./player/Player";

export default () => {
    const location = useLocation();
    const { asset } = location.state;
    let videoUrl = asset?.VideoPlaybackUrl != null ? asset.VideoPlaybackUrl : asset?.Videos.length > 0 ? asset.Videos[0]?.Url ?? '' : '';
    return <>
        <Player src={videoUrl} asset={asset} />
    </>
}